<template>
  <v-form ref="attributeForm" v-model="valid3">
    <v-row>
      <v-col cols="12" md="6">
        <v-autocomplete
          v-model="attribute_value_id"
          :items="attribute_value"
          dense
          chips
          multiple
          small-chips
          label="attribute value"
          item-text="english_title"
          item-value="id"
        ></v-autocomplete>
      </v-col>

      <v-col cols="12" md="6">
        <v-text-field
          v-model="productAttributeForm.actual_price"
          :rules="numberRules"
          :counter="10"
          label="Actual Price"
          outlined
          required
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          v-model="productAttributeForm.discounted_price"
          :rules="numberRules"
          :counter="10"
          label="Discounted Price"
          outlined
          required
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <v-autocomplete
          v-model="productAttributeForm.currency"
          :items="currencies"
          dense
          chips
          small-chips
          label="product currency"
          item-text="title"
          item-value="id"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          v-model="productAttributeForm.quantity"
          :rules="numberRules"
          :counter="10"
          label="Quantity"
          outlined
          required
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          v-model="productAttributeForm.stock_quantity"
          :rules="numberRules"
          :counter="10"
          label="Stock Quantity"
          outlined
          required
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          v-model="productAttributeForm.shipping_weight"
          :rules="textRules"
          :counter="10"
          label="Shipping Weight"
          outlined
          required
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <v-autocomplete
          v-model="productAttributeForm.weight_meg"
          :items="weight_meg"
          dense
          chips
          small-chips
          label="product weight meg"
          item-text="title"
          item-value="value"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          v-model="productAttributeForm.dimensions"
          :rules="textRules"
          :counter="10"
          label="Dimensions"
          outlined
          required
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <v-file-input
          v-model="image0"
          chips
          label="Select Image"
        ></v-file-input>
      </v-col>
      <v-col cols="12" md="6" v-if="file">
        <div class="image-area" style="margin-bottom: 10px;">
          <img
            :src="file.data.full_url"
            v-if="file"
            width="250px"
            height="250px"
          />
          <a
            class="remove-image"
            @click="removeimg(file.id, 1)"
            href="#"
            style="display: inline;"
            >&#215;</a
          >
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="10">
        <v-overlay :value="submitted">
          <v-progress-circular
            indeterminate
            size="64"
          ></v-progress-circular> </v-overlay
      ></v-col>
      <v-col cols="12" md="2">
        <!-- <v-btn color="blue darken-1" text @click="submit">
          <v-progress-circular
            class="mr-2"
            color="primary"
            indeterminate
            size="25"
            v-if="submitted"
          ></v-progress-circular>
          Submit
        </v-btn> -->
        <v-btn color="success" @click="submit">
          Submit
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="12">
        <v-data-table
          :headers="productAttributeValueHeader"
          :items="product_attribute_value"
          :items-per-page="5"
          class="elevation-1 "
        >
          <template v-slot:top>
            <v-toolbar flat class="mb-7">
              <!-- delete item -->
              <v-dialog v-model="dialogDelete" max-width="500px">
                <v-card>
                  <v-card-title class="headline"
                    >Are you sure you want to delete this user?</v-card-title
                  >
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closeDelete"
                      >Cancel</v-btn
                    >
                    <v-btn
                      color="blue darken-1"
                      text
                      @click="deleteItemConfirm()"
                      >OK</v-btn
                    >
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>
          <template
            v-slot:item.actions="{ item }"
            style="textAlign:right !important"
          >
            <v-icon color="primary" small class="mr-2" @click="editAttr(item)">
              mdi-pencil
            </v-icon>

            <v-icon color="error" small class="mr-2" @click="deleteAttr(item)">
              mdi-delete
            </v-icon>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import ApiService from "@/services/api.service";

export default {
  props: ["product_id"],
  data() {
    return {
      submitted_succ: false,
      valid3: false,
      snackbar: false,
      valid: false,
      submitted: false,
      loading: false,
      error: "",
      loadingCategories: false,
      productAttributeValueHeader: [
        {
          text: "discounted price",
          value: "discounted_price",
        },
        {
          text: "actual price",
          value: "actual_price",
        },
        { text: "Actions", value: "actions", sortable: false },
      ],
      product_attribute_value: [],
      attribute_value_id: [],
      attribute_value_id_init: [],
      attribute_value: [],
      currencies: [],
      record: null,
      dialogDelete: false,
      weight_meg: [
        { title: "kg", value: "kg" },
        { title: "gm", value: "gm" },
      ],
      productAttributeForm: {
        id: null,
        image: null,
        discounted_price: null,
        actual_price: null,
        quantity: null,
        stock_quantity: null,
        dimensions: null,
        shipping_weight: null,
        parent_id: null,
        currency: null,
        weight_meg: null,
      },
      image0: null,
      file: null,
      textRules: [(v) => !!v || "field is required"],
      numberRules: [
        (v) => !!v || "field is required",
        (v) =>
          Number.isInteger(Number(v)) || "The value must be an integer number",
        (v) => v > 0 || "The value must be greater than zero",
      ],
      // =================== for updating users ===================
      initialPayload: {},
    };
  },
  methods: {
    async removeimg(id) {
      const { data } = await ApiService.delete(`files/${id}`);
      this.productAttributeForm["image"] = null;
      this.submit();
    },
    resetForm() {
      Object.keys(this.productAttributeForm).forEach((element) => {
        this.productAttributeForm[element] = null;
      });
      this.image0 = null;
      this.file = null;
      this.attribute_value_id = [];
    },
    async editAttr(item) {
      this.attribute_value_id.length = 0;
      this.attribute_value_id_init.length = 0;
      item.attributes.forEach((element) => {
        this.attribute_value_id.push(element.attribute_value_id.id);
        this.attribute_value_id_init.push(element);
      });
      Object.keys(this.productAttributeForm).forEach((element) => {
        if (item[element]) {
          if (typeof item[element] == "object") {
            this.productAttributeForm[element] = item[element].id;
          } else {
            this.productAttributeForm[element] = item[element];
          }
        }
      });
      if (this.productAttributeForm.image) {
        // await this.getFile(this.formData.image);
        const { data } = await ApiService.getFile(
          "files",
          this.productAttributeForm.image
        );
        this.file = data.data;
      }
    },
    async update() {
      this.attribute_value_id_init.forEach(async (element) => {
        const result = this.attribute_value_id.filter(
          (attr) => attr == element.attribute_value_id.id
        );
        if (result.length == 0) {
          await ApiService.delete(
            `items/product_attribute_value/${element.id}`
          );
        }
      });
      this.attribute_value_id.forEach(async (element) => {
        const result = this.attribute_value_id_init.filter(
          (tag) => tag.attribute_value_id.id == element
        );
        if (result.length == 0) {
          let item = {};
          item["product_id"] = this.productAttributeForm.id;
          item["attribute_value_id"] = element;
          await ApiService.post(`items/product_attribute_value`, item);
        }
      });
      await ApiService.update(
        `items/products`,
        this.productAttributeForm.id,
        this.productAttributeForm
      );
    },
    async submit() {
      try {
        if (this.$refs.attributeForm.validate()) {
          this.submitted = true;
          if (this.productAttributeForm.id) {
            await this.update();
          } else {
            if (this.image0) {
              // send file
              let formData3 = new FormData();
              formData3.append("data", this.image0);
              const file = await ApiService.postFile(
                "files",
                formData3,
                0,
                null
              );

              this.productAttributeForm["image"] = file.data.data.id;
            }
            this.productAttributeForm.parent_id = this.product_id;
            const newProduct = await ApiService.post(
              `items/products`,
              this.productAttributeForm
            );

            this.attribute_value_id.forEach(async (element) => {
              let productAttribute = {};
              productAttribute["attribute_value_id"] = element;
              productAttribute["product_id"] = newProduct.data.data.id;

              await ApiService.post(
                "items/product_attribute_value",
                productAttribute
              );
            });
          }
          this.resetForm();
          this.submitted = false;
          this.loadData();
          const msg = this.record
            ? "record updated successfully"
            : "record inserted successfully";
          this.$emit("formSubmitted", this.product_id, msg);
        } else {
          this.snackbar = true;
          this.error = "please fill all required fields";
        }
      } catch (error) {
        if (error.message !== "") {
          this.error = error;
        } else {
          this.error = "sorry, something went wrong";
        }
        this.snackbar = true;
        console.log(error);
        this.submitted = false;
      }
    },
    async loadData() {
      this.loading = true;
      const data = await ApiService.get(
        // `items/products_attribute?fields=id,attribute_value_id.english_title,attribute_value_id.attribute_id.english_title&filter[product_id]=${this.product_id}`
        `items/products?fields=id,image,
        discounted_price,
        actual_price,
        quantity,
        stock_quantity,
        dimensions,
        shipping_weight,
        parent_id,
        currency,attributes.id,attributes.attribute_value_id.english_title,attributes.attribute_value_id.id,attributes.attribute_value_id.attribute_id.english_title&filter[parent_id]=${this.product_id}`
      );
      this.product_attribute_value = data.data.data;
    },
    deleteAttr(record) {
      this.record = record;
      this.dialogDelete = true;
    },
    closeDelete() {
      this.record = null;
      this.dialogDelete = false;
    },
    async deleteItemConfirm() {
      try {
        this.loading = true;
        this.record.attributes.forEach(async (element) => {
          await ApiService.delete(
            `items/product_attribute_value/${element.id}`
          );
        });
        await ApiService.delete(`items/products/${this.record.id}`);
        this.loadData();
        this.loading = false;
        this.closeDelete();

        alert("Product deleted successfully");
        this.loadData();
      } catch (error) {
        console.log(error);
      }
    },
  },
  async mounted() {
    const attribute_value = await ApiService.get(
      "items/attribute_value?fields=id,english_title,attribute_id.id,attribute_id.english_title"
    );
    attribute_value.data.data.forEach((element) => {
      let attribute_value_Obj = {};
      attribute_value_Obj.english_title = `${element.english_title} -> ${element.attribute_id.english_title}`;
      attribute_value_Obj.id = element.id;
      this.attribute_value.push(attribute_value_Obj);
    });

    const currencies = await ApiService.get("items/currency?fields=id,title");
    this.currencies = currencies.data.data;
  },
};
</script>
<style lang="css">
@import "~quill/dist/quill.core.css";
@import "~quill/dist/quill.snow.css";
@import "~quill/dist/quill.bubble.css";
.image-area {
  position: relative;
  width: 50%;
  background: #333;
}
.image-area img {
  max-width: 100%;
  height: auto;
}
.remove-image {
  display: none;
  position: absolute;
  top: -10px;
  right: -10px;
  border-radius: 10em;
  padding: 2px 6px 3px;
  text-decoration: none;
  font: 700 21px/20px sans-serif;
  background: #555;
  border: 3px solid #fff;
  color: #fff;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.5), inset 0 2px 4px rgba(0, 0, 0, 0.3);
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
  -webkit-transition: background 0.5s;
  transition: background 0.5s;
}
.remove-image:hover {
  background: #e54e4e;
  padding: 3px 7px 5px;
  top: -11px;
  right: -11px;
}
.remove-image:active {
  background: #e54e4e;
  top: -10px;
  right: -11px;
}
</style>
