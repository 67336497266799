<template>
  <v-form ref="tagForm" v-model="valid2" style="padding:20px">
    <v-row>
      <v-col cols="12" md="6">
        <v-autocomplete
          v-model="tag_id"
          :items="tags"
          multiple
          dense
          chips
          small-chips
          label="product tags"
          item-text="english_title"
          item-value="id"
        ></v-autocomplete>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="10">
        <v-overlay :value="submitted">
          <v-progress-circular
            indeterminate
            size="64"
          ></v-progress-circular> </v-overlay
      ></v-col>
      <v-col cols="12" md="2">
        <!-- <v-btn color="success" @click="submit">
          Submit
        </v-btn> -->
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import ApiService from "@/services/api.service";

export default {
  props: ["product_id"],
  data() {
    return {
      submitted_succ: false,
      valid2: false,
      snackbar: false,
      valid: false,
      submitted: false,
      loading: false,
      error: "",
      loadingtags: false,
      tags: [],
      tag_id: [],
      tag_id_init: [],
      textRules: [(v) => !!v || "field is required"],

      // =================== for updating users ===================
      initialPayload: {},
    };
  },
  methods: {
    async loadData() {
      this.tag_id_init.length = 0;
      this.tag_id.length = 0;
      this.loading = true;
      const data = await ApiService.get(
        `items/product_tag?fields=id,tag_id.id,tag_id.english_title&filter[product_id]=${this.product_id}`
      );
      let product_attribute_value = data.data.data;
      console.log(product_attribute_value);
      product_attribute_value.forEach((element) => {
        console.log(element.tag_id.id);
        this.tag_id.push(element.tag_id.id);
        this.tag_id_init.push(element);
      });
      this.loading = false;
    },
    remove() {
      this.tag_id_init.forEach(async (element) => {
        const result = this.tag_id.filter((tag) => tag == element.tag_id.id);
        if (result.length == 0) {
          await ApiService.delete(`items/product_tag/${element.id}`);
        }
      });
    },
    add() {
      this.tag_id.forEach(async (element) => {
        const result = this.tag_id_init.filter(
          (tag) => tag.tag_id.id == element
        );
        if (result.length == 0) {
          let item = {};
          item["product_id"] = this.product_id;
          item["tag_id"] = element;
          await ApiService.post(`items/product_tag`, item);
        }
      });
    },
    async submit() {
      try {
        if (this.$refs.tagForm.validate()) {
          this.submitted = true;
          await this.remove();
          await this.add();
          this.submitted = false;
          this.submitted_succ = true;

          const msg = this.record
            ? "record updated successfully"
            : "record inserted successfully";
          this.$emit("formSubmitted", this.product_id, msg);
        } else {
          this.snackbar = true;
          this.error = "please fill all required fields";
        }
      } catch (error) {
        if (error.message !== "") {
          this.error = error;
        } else {
          this.error = "sorry, something went wrong";
        }
        this.snackbar = true;
        console.log(error);
        this.submitted = false;
      }
    },
  },

  async mounted() {
    const tags = await ApiService.get("items/tags?fields=id,english_title");
    this.tags = tags.data.data;
  },
};
</script>
<style lang="css">
@import "~quill/dist/quill.core.css";
@import "~quill/dist/quill.snow.css";
@import "~quill/dist/quill.bubble.css";
.image-area {
  position: relative;
  width: 50%;
  background: #333;
}
.image-area img {
  max-width: 100%;
  height: auto;
}
.remove-image {
  display: none;
  position: absolute;
  top: -10px;
  right: -10px;
  border-radius: 10em;
  padding: 2px 6px 3px;
  text-decoration: none;
  font: 700 21px/20px sans-serif;
  background: #555;
  border: 3px solid #fff;
  color: #fff;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.5), inset 0 2px 4px rgba(0, 0, 0, 0.3);
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
  -webkit-transition: background 0.5s;
  transition: background 0.5s;
}
.remove-image:hover {
  background: #e54e4e;
  padding: 3px 7px 5px;
  top: -11px;
  right: -11px;
}
.remove-image:active {
  background: #e54e4e;
  top: -10px;
  right: -11px;
}
</style>
