<template>
  <v-dialog v-model="dialog" max-width="900px" persistent>
    <v-card>
      <v-card-title>
        <span color="primary" class="headline">{{ formTitle }}</span>
      </v-card-title>

      <v-card-text>
        <v-stepper v-model="e1" class="elevation-0 p-0 m-0">
          <v-stepper-header>
            <v-stepper-step :complete="e1 > 1" step="1">
              Product Info
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step :complete="e1 > 2" step="2">
              Categories
            </v-stepper-step>

            <v-stepper-step :complete="e1 > 3" step="3">
              Tags
            </v-stepper-step>

            <v-stepper-step :complete="e1 > 4" step="4">
              attribute
            </v-stepper-step>

            <v-stepper-step :complete="e1 > 5" step="5">
              Media
            </v-stepper-step>
          </v-stepper-header>

          <v-stepper-items>
            <v-stepper-content step="1">
              <ProductData
                @formSubmitted="formSubmitted"
                :record="record"
                :userId="userId"
              />

              <v-btn color="primary" @click="next(2)" v-if="this.product_id">
                Continue
              </v-btn>
              <!-- <v-btn color="success" @click="udateProduct" v-if="record">
                update
              </v-btn> -->
            </v-stepper-content>

            <v-stepper-content step="2">
              <!-- ==================== chapters form ==================== -->
              <ProductCategories
                ref="ProductCategories"
                :product_id="product_id"
              />

              <v-btn text @click="back(1)">
                Back
              </v-btn>

              <v-btn color="primary" @click="next(3)">
                Continue
              </v-btn>
            </v-stepper-content>

            <v-stepper-content step="3">
              <!-- ==================== content form ==================== -->
              <ProductTags ref="ProductTags" :product_id="product_id" />

              <v-btn text @click="back(2)">
                Back
              </v-btn>
              <v-btn color="primary" @click="next(4)">
                Continue
              </v-btn>
            </v-stepper-content>

            <v-stepper-content step="4">
              <!-- ==================== content form ==================== -->
              <ProductAttributes
                :product_id="product_id"
                ref="ProductAttributes"
              />
              <v-btn text @click="back(3)">
                Back
              </v-btn>
              <v-btn color="primary" @click="next(5)">
                Continue
              </v-btn>
              <!-- <v-btn
                color="success"
                @click="udateProductAttributes"
                v-if="record"
              >
                update
              </v-btn> -->
            </v-stepper-content>

            <v-stepper-content step="5">
              <!-- ==================== content form ==================== -->
              <ProductMedias :product_id="product_id" ref="ProductMedias" />

              <v-btn text @click="back(4)">
                Back
              </v-btn>
              <!-- <v-btn color="success" @click="udateProductMedia" v-if="record">
                update
              </v-btn> -->
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error darken-1" text @click="close">
          Cancel
        </v-btn>
        <!-- <v-btn color="blue darken-1" text @click="submit">
          Submit
        </v-btn> -->
      </v-card-actions>
    </v-card>

    <!-- snackbar -->
    <!-- snackbar -->
    <v-snackbar
      v-model="snackbar"
      absolute
      top
      :multi-line="true"
      :color="snackbarColor"
    >
      {{ snackbarMsg }}
      <template v-slot:action="{ attrs }">
        <v-btn color="red" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-dialog>
</template>

<script>
import ApiService from "@/services/api.service";
// import { delete } from "vue/types/umd";
// import { mapActions } from "vuex";
import ProductData from "./ProductData";
import ProductCategories from "./ProductCategories";
import ProductTags from "./ProductTags";
import ProductAttributes from "./ProductAttributes";
import ProductMedias from "./ProductMedias";

export default {
  components: {
    ProductData,
    ProductCategories,
    ProductTags,
    ProductAttributes,
    ProductMedias,
  },
  props: ["formTitle", "record", "dialog", "userId"],
  data() {
    return {
      snackbar: false,
      snackbarMsg: null,
      snackbarColor: "success",
      product_id: null,
      categories: [],
      tags: [],
      attributes: [],
      attribute_value: [],
      authors: [],
      publishers: [],
      languages: [],
      brands: [],
      currencies: [],
      snackbar: false,
      e1: 1,
      valid: false,
      valid2: false,
      valid3: false,
      submitted: false,
      loading: false,
      attribute: null,
      error: "",
      media: null,
      type: null,
      genre: null,
      file: null,
      image: null,
      collection: "products",

      textRules: [(v) => !!v || "Name is required"],

      // =================== for update ===================
      initialPayload: {},
    };
  },
  methods: {
    next(id) {
      switch (id) {
        case 2:
          if (this.product_id) {
            this.$refs.ProductCategories.loadData();
            this.e1 = id;
          } else {
            alert("you must insert or select product");
          }
          break;
        case 3:
          this.$refs.ProductTags.loadData();
          this.$refs.ProductCategories.submit();
          this.e1 = id;
          break;
        case 4:
          this.$refs.ProductAttributes.loadData();
          this.$refs.ProductTags.submit();
          this.e1 = id;
          break;
        case 5:
          this.$refs.ProductMedias.loadData();
          this.e1 = id;
          break;
      }
    },
    back(id) {
      switch (id) {
        case 1:
          // this.$refs.ProductCategory.submit();
          this.e1 = id;
          break;
        case 2:
          this.$refs.ProductCategories.loadData();
          this.e1 = id;
          break;
        case 3:
          this.$refs.ProductTags.loadData();
          // this.$refs.ProductAttributes.submit();
          this.e1 = id;
          break;
        case 4:
          this.$refs.ProductAttributes.loadData();
          // this.$refs.ProductMedia.submit();
          this.e1 = id;
          break;
      }
    },
    // editAttr(item) {
    //   console.log(item.image0);
    //   this.image0 = item.image0;
    //   delete item.image0;
    //   Object.keys(this.productAttributeForm).forEach((element) => {
    //     if (item[element]) {
    //       this.productAttributeForm[element] = item[element];
    //     }
    //   });
    // },
    // deleteAttr(item) {},
    // editMedia(item) {},
    // deleteMedia(item) {},
    // async udateProduct() {
    //   await ApiService.update(
    //     `items/products`,
    //     this.record.id,
    //     this.productForm
    //   );
    // },
    // async udateProductMedia() {},
    // async udateProductCategories() {
    //   this.record.categories.forEach(async (element) => {
    //     const result = this.productCategoryForm.category_id.filter(
    //       (category) => category == element.category_id.id
    //     );
    //     if (result.length == 0) {
    //       await ApiService.delete(`items/product_category/${element.id}`);
    //     }
    //   });
    //   this.productCategoryForm.category_id.forEach(async (element) => {
    //     const result = this.record.categories.filter(
    //       (category) => category.category_id.id == element
    //     );
    //     if (result.length == 0) {
    //       let item = {};
    //       item["product_id"] = this.record.id;
    //       item["category_id"] = element;
    //       console.log(item);
    //       await ApiService.post(`items/product_category`, item);
    //     }
    //   });
    // },
    // async udateProductTags() {
    //   this.record.tags.forEach(async (element) => {
    //     const result = this.productTagForm.tag_id.filter(
    //       (tag) => tag == element.tag.id
    //     );
    //     if (result.length == 0) {
    //       await ApiService.delete(`items/product_tag/${element.id}`);
    //     }
    //   });
    //   this.productTagForm.tag_id.forEach(async (element) => {
    //     const result = this.record.tags.filter(
    //       (tag) => tag.tag_id.id == element
    //     );
    //     if (result.length == 0) {
    //       let item = {};
    //       item["product_id"] = this.record.id;
    //       item["tag_id"] = element;
    //       console.log(item);
    //       await ApiService.post(`items/product_tag`, item);
    //     }
    //   });
    // },
    // async udateProductAttributes() {
    //   const result = this.record.child_products.filter(
    //     (product) => product.id == this.productAttributeForm.id
    //   );

    //   result[0].attributes.forEach(async (element) => {
    //     const result1 = this.productAttributeForm.attribute_value_id.filter(
    //       (attr) => attr == element.attribute_value_id.id
    //     );
    //     if (result1.length == 0) {
    //       await ApiService.delete(
    //         `items/product_attribute_value/${element.id}`
    //       );
    //     }
    //   });
    //   this.productAttributeForm.attribute_value_id.forEach(async (element) => {
    //     const result2 = result[0].attributes.filter(
    //       (attr) => attr.attribute_value_id.id == element
    //     );
    //     if (result2.length == 0) {
    //       let item = {};
    //       item["product_id"] = this.productAttributeForm.id;
    //       item["attribute_value_id"] = element;
    //       console.log(item);
    //       await ApiService.post(`items/product_attribute_value`, item);
    //     }
    //   });

    //   ///
    //   delete this.productAttributeForm.attribute_value_id;
    //   delete this.productAttributeForm.image0;
    //   console.log(this.productAttributeForm);
    //   await ApiService.update(
    //     `items/products`,
    //     this.productAttributeForm.id,
    //     this.productAttributeForm
    //   );
    // },
    // submitProductAttribute() {
    //   let objectItem = {};
    //   Object.keys(this.productAttributeForm).forEach((element) => {
    //     objectItem[element] = this.productAttributeForm[element];
    //   });
    //   this.product_attribute_value.push(objectItem);
    // },
    // submitProductMedia() {
    //   let objectItem = {};
    //   Object.keys(this.productMediaForm).forEach((element) => {
    //     objectItem[element] = this.productMediaForm[element];
    //   });
    //   this.product_media.push(objectItem);
    //   productMediaForm.video_link = null;
    //   productMediaForm.image0 = null;
    // },
    // async updateRecord() {
    //   try {
    //   } catch (error) {
    //     console.log(error);
    //   }
    // },
    // // async getAttributeValues() {
    // //   this.productAttributeForm.attribute_value_id = null;
    // //   const attribute_value = await ApiService.get(
    // //     `items/attribute_value?fields=id,english_title&filter[attribute_id]=${this.attribute}`
    // //   );
    // //   this.attribute_value = attribute_value.data.data;
    // // },
    // async submit() {
    //   try {
    //     if (this.$refs.form.validate()) {
    //       this.submitted = true;
    //       if (this.image) {
    //         // send file
    //         let formData = new FormData();
    //         formData.append("data", this.image);
    //         const file = await ApiService.postFile("files", formData, 0, null);
    //         // send post req
    //         this.productForm["image"] = file.data.data.id;
    //       }
    //       if (this.record) {
    //         await ApiService.update(
    //           `items/${this.collection}`,
    //           this.productForm.id,
    //           this.productForm
    //         );
    //       } else {
    //         console.log(this.productForm);

    //         this.product_attribute_value.forEach(async (element) => {
    //           if (element.image0) {
    //             // send file
    //             let formData3 = new FormData();
    //             formData3.append("data", element.image0);
    //             const file = await ApiService.postFile(
    //               "files",
    //               formData3,
    //               0,
    //               null
    //             );
    //             // send post req
    //             delete element.image0;
    //             element["image"] = file.data.data.id;
    //           }

    //           let attribute_value_id = element.attribute_value_id;
    //           delete element.attribute_value_id;
    //           element.parent_id = productItem.data.data.id;
    //           console.log(element);
    //           const newProduct = await ApiService.post(
    //             `items/${this.collection}`,
    //             element
    //           );

    //           attribute_value_id.forEach(async (element) => {
    //             let productAttribute = {};
    //             productAttribute["attribute_value_id"] = element;
    //             productAttribute["product_id"] = newProduct.data.data.id;

    //             await ApiService.post(
    //               "items/product_attribute_value",
    //               productAttribute
    //             );
    //           });
    //         });

    //         this.product_media.forEach(async (element) => {
    //           if (element.image0) {
    //             // send file
    //             let formData4 = new FormData();
    //             formData4.append("data", element.image0);
    //             const file = await ApiService.postFile(
    //               "files",
    //               formData4,
    //               0,
    //               null
    //             );
    //             // send post req

    //             element["image"] = file.data.data.id;
    //           }
    //           delete element.image0;
    //           element["product_id"] = productItem.data.data.id;
    //           console.log(element);
    //           await ApiService.post("items/product_media", element);
    //         });
    //       }
    //       this.submitted = false;
    //       const msg = this.record
    //         ? "record updated successfully"
    //         : "record inserted successfully";
    //       this.$emit("formSubmitted", msg);
    //     } else {
    //       this.snackbar = true;
    //       this.error = "please fill all required fields";
    //     }
    //   } catch (error) {
    //     if (error.message !== "") {
    //       this.error = error;
    //     } else {
    //       this.error = "sorry, something went wrong";
    //     }
    //     this.snackbar = true;
    //     console.log(error);
    //     this.submitted = false;
    //   }
    // },
    close() {
      this.$emit("closeDialog");
    },
    formSubmitted(id, msg) {
      this.snackbarMsg = msg;
      this.snackbar = true;
      this.product_id = id;
      this.$emit("formSubmitted");
    },
  },

  async mounted() {
    if (this.record) {
      this.product_id = this.record.id;
    }

    // const authors = await ApiService.get(
    //   "items/author?fields=id,english_title"
    // );
    // this.authors = authors.data.data;

    // const publishers = await ApiService.get(
    //   "items/publisher?fields=id,english_title"
    // );
    // this.publishers = publishers.data.data;

    // const brands = await ApiService.get("items/brands?fields=id,english_title");
    // this.brands = brands.data.data;

    // const languages = await ApiService.get(
    //   "items/languages?fields=id,english_title"
    // );
    // this.languages = languages.data.data;

    // const currencies = await ApiService.get("items/currency?fields=id,title");
    // this.currencies = currencies.data.data;
  },
};
</script>
<style lang="css">
@import "~quill/dist/quill.core.css";
@import "~quill/dist/quill.snow.css";
@import "~quill/dist/quill.bubble.css";
.image-area {
  position: relative;
  width: 50%;
  background: #333;
}
.image-area img {
  max-width: 100%;
  height: auto;
}
.remove-image {
  display: none;
  position: absolute;
  top: -10px;
  right: -10px;
  border-radius: 10em;
  padding: 2px 6px 3px;
  text-decoration: none;
  font: 700 21px/20px sans-serif;
  background: #555;
  border: 3px solid #fff;
  color: #fff;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.5), inset 0 2px 4px rgba(0, 0, 0, 0.3);
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
  -webkit-transition: background 0.5s;
  transition: background 0.5s;
}
.remove-image:hover {
  background: #e54e4e;
  padding: 3px 7px 5px;
  top: -11px;
  right: -11px;
}
.remove-image:active {
  background: #e54e4e;
  top: -10px;
  right: -11px;
}
</style>
