<template>
  <v-container fluid>
    <v-card>
      <v-card-title>
        <span color="primary" class="headline">Search</span>
      </v-card-title>

      <v-card-text>
        <v-form ref="form" style="padding:50px">
          <v-row>
            <v-col cols="12" md="3">
              <v-autocomplete
                v-model="searchForm.type"
                :items="types"
                clearable
                dense
                chips
                small-chips
                label="Product Type"
                item-text="title"
                item-value="value"
                @change="loadData"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" md="3">
              <v-autocomplete
                v-model="searchForm.status"
                :items="status"
                clearable
                dense
                chips
                small-chips
                label="Product Status"
                item-text="title"
                item-value="value"
                @change="loadData"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" md="3">
              <v-autocomplete
                v-model="searchForm.genre"
                :items="genres"
                clearable
                dense
                chips
                small-chips
                label="Product Genre"
                item-text="title"
                item-value="value"
                @change="loadData"
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="12" md="3">
              <v-autocomplete
                v-model="searchForm.book_type_id"
                :items="book_type_id"
                clearable
                dense
                chips
                small-chips
                label="Book Type"
                item-text="english_title"
                item-value="id"
                @change="loadData"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" md="3">
              <v-autocomplete
                v-model="author"
                :items="authors"
                clearable
                dense
                chips
                small-chips
                label="product author"
                item-text="arabic_title"
                item-value="id"
                @change="loadData"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" md="3">
              <v-autocomplete
                v-model="searchForm.publisher"
                :items="publishers"
                clearable
                dense
                chips
                small-chips
                label="Product Publisher"
                item-text="arabic_title"
                item-value="id"
                @change="loadData"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" md="3">
              <v-autocomplete
                v-model="countery_id"
                :items="counteries"
                dense
                chips
                small-chips
                label="Counteries"
                item-text="arabic_title"
                item-value="id"
                @change="loadData"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" md="3">
              <v-autocomplete
                v-model="searchForm.brand_id"
                :items="brands"
                clearable
                dense
                chips
                small-chips
                label="Product Brand"
                item-text="english_title"
                item-value="id"
                @change="loadData"
              ></v-autocomplete>
            </v-col>
            <!-- <v-col cols="6" md="3">
              <v-btn color="success" @click="loadData">
                Search
              </v-btn>
            </v-col> -->
            <v-col cols="12" md="3">
              <v-text-field
                v-model="search"
                label="general"
                single-line
                hide-details
              ></v-text-field>
            </v-col> </v-row
        ></v-form>
      </v-card-text>
    </v-card>
    <v-data-table
      :headers="productsHeader"
      :items="products"
      :loading="loading"
      :items-per-page="100"
      :search="search"
      class="elevation-1"
    >
      <template v-slot:top>
        <v-toolbar flat class="mb-7">
          <v-toolbar-title>Products</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <!-- <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field> -->
          <v-spacer></v-spacer>

          <!-- edit item component -->
          <ProductForm
            @closeDialog="close"
            @formSubmitted="formSubmitted"
            :formTitle="formTitle"
            :dialog="openDialog"
            :record="record"
            v-if="openDialog"
            :userId="userId"
          />

          <v-btn
            color="primary"
            dark
            class="mb-2"
            @click="
              () => {
                record = null;
                formTitle = 'Add New Product';
                openDialog = true;
              }
            "
          >
            <v-icon small>mdi-plus</v-icon>
          </v-btn>

          <!-- delete item -->
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="headline"
                >Are you sure you want to delete this user?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete"
                  >Cancel</v-btn
                >
                <v-btn color="blue darken-1" text @click="deleteItemConfirm()"
                  >OK</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.arDesc="{ item }">
        <v-checkbox v-model="check" v-if="item.arabic_description"></v-checkbox>
      </template>
      <template v-slot:item.image="{ item }">
        <v-img
          width="40px"
          height="40px"
          :src="item.image.data.full_url"
          v-if="item.image"
        />
      </template>
      <template v-slot:item.color="{ item }">
        <div
          v-if="item.color"
          v-bind:style="{
            borderRadius: '25px',
            width: '15px',
            height: '15px',
            background: item.color,
          }"
        />
      </template>
      <!-- <template
        v-slot:item.title="{ item }"
        style="textAlign:right !important;width:180px"
      ></template> -->

      <template v-slot:table-colgroup="scope">
        <col
          v-for="field in scope.fields"
          :key="field.key"
          :style="{
            width: field.key === 'title' ? '130px' : 'auto',
          }"
        />
      </template>

      <template
        v-slot:item.actions="{ item }"
        style="textAlign:right !important"
      >
        <v-icon color="primary" small class="mr-2" @click="editItem(item)">
          mdi-pencil
        </v-icon>

        <v-icon color="error" small class="mr-2" @click="deleteItem(item)">
          mdi-delete
        </v-icon>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import ProductForm from "./comp/ProductForm";
import ApiService from "@/services/api.service";

export default {
  components: {
    ProductForm,
  },
  data() {
    return {
      search: null,
      productsHeader: [
        { text: "Title", value: "arabic_title" },
        // { text: "Sequence", value: "sequence" },
        { text: "Authors", value: "author_name" },
        { text: "publishers", value: "publisher.arabic_title" },
        { text: "Description", value: "arDesc" },
        { text: "Quantity", value: "quantity" },
        { text: "Stock quantity", value: "stock_quantity" },
        // { text: "Currency", value: "currency.title" },
        { text: "Actual", value: "actual_price" },
        { text: "Discounted", value: "discounted_price" },
        { text: "Sku", value: "sku" },
        { text: "Height", value: "height" },
        { text: "Shipping weight", value: "shipping_weight" },
        { text: "Avatar", value: "image" },
        // { text: "Genre", value: "genre1" },
        { text: "Status", value: "color" },
        // { text: "color", value: "color" },

        // { text: "color", value: "color" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      loading: false,
      openDialog: false,
      dialogDelete: false,
      formTitle: "",
      products: [],
      record: null,
      check: true,
      types: [
        { title: "Simple", value: "S" },
        { title: "Has Variations", value: "V" },
      ],
      genres: [
        { title: "Book", value: "B" },
        { title: "Item", value: "I" },
      ],
      userId: null,
      status: [
        { title: "Published", value: "published" },
        { title: "Draft", value: "draft" },
        { title: "Rejected", value: "rejected" },
      ],
      book_type_id: [],
      authors: [],
      brands: [],
      publishers: [],
      searchForm: {
        type: null,
        genre: null,
        sku: null,
        brand_id: null,
        book_type_id: null,
        publisher: null,
        status: null,
      },
      counteries: [],
      author: null,
      countery_id: null,
    };
  },
  methods: {
    close() {
      this.loadData();
      this.openDialog = false;
    },
    async loadData() {
      try {
        let url = `items/products?fields=*,notes,publisher.id,publisher.arabic_title,publisher.countery_id,currency.title,currency.id,image.*,image.data.full_url,book_type_id.english_title,attributes.id,child_products.image,child_products.id,child_products.attributes.id,categories.id,tags.id,attributes.id,media.id,categories.category_id.english_title,categories.category_id.id,tags.tag_id.english_title,tags.tag_id.id,child_products.attributes.attribute_value_id.english_title,child_products.attributes.attribute_value_id.id,child_products.attributes.attribute_value_id.attribute_id.english_title,child_products.attributes.attribute_value_id.attribute_id.id,child_products.*,media.*,book_type_id.id,authors.author_id.id,authors.author_id.arabic_title,translators.author_id.id,authors.id,translators.id&filter[parent_id][null]&filter[status][neq]=daleted&limit=-1&sort=-quantity,+arabic_title`;
        this.loading = true;
        Object.keys(this.searchForm).forEach((element) => {
          if (this.searchForm[element]) {
            url = url + `&filter[${element}]=${this.searchForm[element]}`;
          }
        });
        if (this.author) {
          url = url + `&filter[authors.author_id.id]=${this.author}`;
        }
        if (this.countery_id) {
          url = url + `&filter[publisher.countery_id]=${this.countery_id}`;
        }
        const data = await ApiService.get(url);
        let products = data.data.data;
        let productArray = [];
        for (let product of products) {
          // if (product.image) {
          //   const { data } = await ApiService.getFile(
          //     "files",
          //     product.image.id
          //   );
          //   // if (data.data.data.thumbnails) {
          //   //   product.iconUrl = data.data.data.thumbnails[6].url;
          //   // } else {
          //   product.iconUrl = data.data.data.full_url;
          //   // }
          // }
          // switch (product.status) {
          //   case "published":
          //     product.color = "green";
          //     break;
          //   case "draft":
          //     product.color = "gray";
          //     break;
          //   case "rejected":
          //     product.color = "red";
          //     break;
          // }
          if (product.status == "published") {
            product.color = "green";
          } else if (product.status == "draft") {
            product.color = "gray";
          } else if (product.status == "rejected") {
            product.color = "red";
          }

          let author_name = null;
          for (let authors of product.authors) {
            if (author_name) {
              author_name =
                author_name + " - " + authors.author_id.arabic_title;
            } else {
              author_name = authors.author_id.arabic_title;
            }
          }
          product.author_name = author_name;
          if (product.type == "S") {
            product.type1 = "Simple";
          } else if (product.type == "V") {
            product.type1 = "With Variables";
          }
          if (product.genre == "B") {
            product.genre1 = "Book";
          } else if (product.genre == "I") {
            product.genre1 = "Item";
          }
          productArray.push(product);
        }

        this.products = productArray;

        this.loading = false;
      } catch (error) {
        console.log(error);
      }
    },

    editItem(item) {
      // console.log(item);
      this.record = item;
      this.formTitle = "Edit Product";
      this.openDialog = true;
    },

    deleteItem(record) {
      this.record = record;
      this.dialogDelete = true;
    },

    async deleteItemConfirm() {
      try {
        this.loading = true;
        await ApiService.update(`items/products`, this.record.id, {
          status: "deleted",
        });
        this.loading = false;
        this.closeDelete();

        alert("Product deleted successfully");
        this.loadData();
      } catch (error) {
        console.log(error);
      }
    },

    closeDelete() {
      this.record = null;
      this.dialogDelete = false;
    },

    formSubmitted() {
      this.loadData();
    },
  },
  async mounted() {
    let info = await JSON.parse(ApiService.getUserInfo());
    this.userId = info.id;
    this.loadData();
    const counteries = await ApiService.get(
      "items/counteries?fields=id,arabic_title,english_title"
    );
    this.counteries = counteries.data.data;

    const authors = await ApiService.get(
      "items/author?fields=id,arabic_title&sort=arabic_title"
    );
    this.authors = authors.data.data;

    const publishers = await ApiService.get(
      "items/publisher?fields=id,arabic_title&sort=arabic_title"
    );
    this.publishers = publishers.data.data;

    const brands = await ApiService.get("items/brands?fields=id,english_title");
    this.brands = brands.data.data;

    const book_type_id = await ApiService.get(
      "items/book_type?fields=id,english_title"
    );
    this.book_type_id = book_type_id.data.data;
  },
};
</script>

<style></style>
