<template>
  <v-form ref="categoryForm" v-model="valid2" style="padding:20px">
    <v-row>
      <v-col cols="12" md="6">
        <v-autocomplete
          v-model="category_id"
          :items="categories"
          multiple
          dense
          chips
          small-chips
          label="pharmacy categories"
          item-text="arabic_title"
          item-value="id"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" md="6">
        <v-autocomplete
          v-model="category_id"
          :items="categories1"
          multiple
          dense
          chips
          small-chips
          label="library categories"
          item-text="arabic_title"
          item-value="id"
        ></v-autocomplete>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="10">
        <v-overlay :value="submitted">
          <v-progress-circular
            indeterminate
            size="64"
          ></v-progress-circular> </v-overlay
      ></v-col>
      <v-col cols="12" md="2">
        <!-- <v-btn color="success" @click="submit">
          Submit
        </v-btn> -->
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import ApiService from "@/services/api.service";

export default {
  props: ["product_id"],
  data() {
    return {
      valid2: false,
      snackbar: false,
      valid: false,
      submitted: false,
      loading: false,
      error: "",
      loadingCategories: false,
      categories: [],
      categories1: [],
      category_id: [],
      category_id_init: [],
      submitted_succ: false,
      textRules: [(v) => !!v || "field is required"],

      // =================== for updating users ===================
      initialPayload: {},
    };
  },
  methods: {
    async loadData() {
      this.category_id_init.length = 0;
      this.category_id.length = 0;
      this.loading = true;
      const data = await ApiService.get(
        `items/product_category?fields=id,category_id.id,category_id.english_title,category_id.arabic_title&filter[product_id]=${this.product_id}`
      );
      let product_attribute_value = data.data.data;
      console.log(product_attribute_value);
      product_attribute_value.forEach((element) => {
        console.log(element.category_id.id);
        this.category_id.push(element.category_id.id);
        this.category_id_init.push(element);
      });
      this.loading = false;
    },
    remove() {
      this.category_id_init.forEach(async (element) => {
        const result = this.category_id.filter(
          (category) => category == element.category_id.id
        );
        if (result.length == 0) {
          await ApiService.delete(`items/product_category/${element.id}`);
        }
      });
    },
    add() {
      this.category_id.forEach(async (element) => {
        const result = this.category_id_init.filter(
          (category) => category.category_id.id == element
        );
        if (result.length == 0) {
          let item = {};
          item["product_id"] = this.product_id;
          item["category_id"] = element;
          await ApiService.post(`items/product_category`, item);
        }
      });
    },
    async submit() {
      try {
        if (this.$refs.categoryForm.validate()) {
          this.submitted = true;
          await this.remove();
          await this.add();
          this.submitted = false;
          this.submitted_succ = true;

          //this.$emit("formSubmitted", "categories ok");
          const msg = this.record
            ? "record updated successfully"
            : "record inserted successfully";
          this.$emit("formSubmitted", this.product_id, msg);
        } else {
          this.snackbar = true;
          this.error = "please fill all required fields";
        }
      } catch (error) {
        if (error.message !== "") {
          this.error = error;
        } else {
          this.error = "sorry, something went wrong";
        }
        this.snackbar = true;
        console.log(error);
        this.submitted = false;
      }
    },
  },

  async mounted() {
    const categories = await ApiService.get(
      "items/categories?fields=id,english_title,arabic_title,parent_category.id,child_categories.id,child_categories.parent_category&sort=arabic_title"
    );
    categories.data.data.forEach((element) => {
      if (element.child_categories.length == 0) {
        if (element.parent_category && element.parent_category.id == 103) {
          this.categories1.push(element);
        } else {
          this.categories.push(element);
        }
      }
    });
  },
};
</script>
<style lang="css">
@import "~quill/dist/quill.core.css";
@import "~quill/dist/quill.snow.css";
@import "~quill/dist/quill.bubble.css";
.image-area {
  position: relative;
  width: 50%;
  background: #333;
}
.image-area img {
  max-width: 100%;
  height: auto;
}
.remove-image {
  display: none;
  position: absolute;
  top: -10px;
  right: -10px;
  border-radius: 10em;
  padding: 2px 6px 3px;
  text-decoration: none;
  font: 700 21px/20px sans-serif;
  background: #555;
  border: 3px solid #fff;
  color: #fff;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.5), inset 0 2px 4px rgba(0, 0, 0, 0.3);
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
  -webkit-transition: background 0.5s;
  transition: background 0.5s;
}
.remove-image:hover {
  background: #e54e4e;
  padding: 3px 7px 5px;
  top: -11px;
  right: -11px;
}
.remove-image:active {
  background: #e54e4e;
  top: -10px;
  right: -11px;
}
</style>
