<template>
  <v-form ref="productMediaForm" v-model="valid3">
    <v-row>
      <v-col cols="12" md="6">
        <v-autocomplete
          v-model="productMediaForm.type"
          :items="medias"
          dense
          chips
          small-chips
          label="media type"
          item-text="title"
          item-value="value"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          :disabled="productMediaForm.type == 'I'"
          v-model="productMediaForm.video_link"
          label="Video Link"
          required
          type="text"
          outlined
          dense
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="6">
        <v-file-input
          :disabled="productMediaForm.type == 'V'"
          v-model="image0"
          chips
          label="Select Image"
        ></v-file-input>
      </v-col>
      <v-col cols="12" md="6" v-if="file">
        <div class="image-area" style="margin-bottom: 10px;">
          <img
            :src="file.data.full_url"
            v-if="file"
            width="250px"
            height="250px"
          />
          <a
            class="remove-image"
            @click="removeimg(file.id, 1)"
            href="#"
            style="display: inline;"
            >&#215;</a
          >
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="10">
        <v-overlay :value="submitted">
          <v-progress-circular
            indeterminate
            size="64"
          ></v-progress-circular> </v-overlay
      ></v-col>
      <v-col cols="12" md="2">
        <v-btn color="success" @click="submit">
          Submit
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="12">
        <v-data-table
          :headers="productMediaHeader"
          :items="product_media"
          :items-per-page="5"
          class="elevation-1 "
        >
          <template v-slot:top>
            <v-toolbar flat class="mb-7">
              <!-- delete item -->
              <v-dialog v-model="dialogDelete" max-width="500px">
                <v-card>
                  <v-card-title class="headline"
                    >Are you sure you want to delete this user?</v-card-title
                  >
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closeDelete"
                      >Cancel</v-btn
                    >
                    <v-btn
                      color="blue darken-1"
                      text
                      @click="deleteItemConfirm()"
                      >OK</v-btn
                    >
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>
          <template
            v-slot:item.actions="{ item }"
            style="textAlign:right !important"
          >
            <v-icon color="primary" small class="mr-2" @click="editMedia(item)">
              mdi-pencil
            </v-icon>

            <v-icon color="error" small class="mr-2" @click="deleteMedia(item)">
              mdi-delete
            </v-icon>
          </template>
          <template v-slot:item.image="{ item }">
            <v-img width="40px" height="40px" :src="item.iconUrl" />
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import ApiService from "@/services/api.service";

export default {
  props: ["product_id"],
  data() {
    return {
      submitted_succ: false,
      valid3: false,
      snackbar: false,
      valid: false,
      submitted: false,
      loading: false,
      error: "",
      loadingCategories: false,
      medias: [
        { title: "Image", value: "I" },
        { title: "Video", value: "V" },
      ],
      productMediaForm: {
        id: null,
        video_link: null,
        type: null,
        image: null,
      },
      productMediaHeader: [
        { text: "Title", value: "type" },
        { text: "image", value: "image" },
        { text: "video", value: "video_link" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      product_media: [],
      product_media_init: [],
      record: null,
      dialogDelete: false,
      file: null,
      image0: null,
      textRules: [(v) => !!v || "field is required"],

      // =================== for updating users ===================
      initialPayload: {},
    };
  },
  methods: {
    async removeimg(id) {
      const { data } = await ApiService.delete(`files/${id}`);
      this.productMediaForm["image"] = null;
      this.submit();
    },
    resetForm() {
      Object.keys(this.productMediaForm).forEach((element) => {
        this.productMediaForm[element] = null;
      });
      this.image0 = null;
      this.file = null;
      this.product_media = [];
    },
    async editMedia(item) {
      Object.keys(this.productMediaForm).forEach((element) => {
        if (item[element]) {
          if (typeof item[element] == "object") {
            this.productMediaForm[element] = item[element].id;
          } else {
            this.productMediaForm[element] = item[element];
          }
        }
      });
      if (this.productMediaForm.image) {
        // await this.getFile(this.formData.image);
        const { data } = await ApiService.getFile(
          "files",
          this.productMediaForm.image
        );
        this.file = data.data;
        console.log(this.file);
      }
    },
    async update() {
      await ApiService.update(
        `items/product_media`,
        this.productMediaForm.id,
        this.productMediaForm
      );
    },
    async submit() {
      try {
        if (this.$refs.productMediaForm.validate()) {
          this.submitted = true;
          console.log(this.image0);

          if (this.image0) {
            // send file
            console.log(this.image0);
            let formData3 = new FormData();
            formData3.append("data", this.image0);
            const data = await ApiService.postFile("files", formData3, 0, null);
            this.productMediaForm["image"] = data.data.data.id;
          }
          this.productMediaForm.product_id = this.product_id;
          console.log(this.productMediaForm);
          if (this.productMediaForm.id) {
            await this.update();
          } else {
            const newProduct = await ApiService.post(
              `items/product_media`,
              this.productMediaForm
            );
          }
          this.resetForm();
          this.submitted = false;
          this.loadData();
          const msg = this.record
            ? "record updated successfully"
            : "record inserted successfully";
          this.$emit("formSubmitted", this.product_id, msg);
        } else {
          this.snackbar = true;
          this.error = "please fill all required fields";
        }
      } catch (error) {
        if (error.message !== "") {
          this.error = error;
        } else {
          this.error = "sorry, something went wrong";
        }
        this.snackbar = true;
        console.log(error);
        this.submitted = false;
      }
    },
    async loadData() {
      // this.resetForm();
      this.loading = true;
      const data = await ApiService.get(
        `items/product_media?fields=id,image,video_link,type&filter[product_id]=${this.product_id}`
      );
      let product_media = [];
      for (let product of data.data.data) {
        if (product.image) {
          const { data } = await ApiService.getFile("files", product.image);
          product.iconUrl = data.data.data.thumbnails[6].url;
        }

        product_media.push(product);
      }
      this.product_media = product_media;
    },
    deleteMedia(record) {
      this.record = record;
      this.dialogDelete = true;
    },
    closeDelete() {
      this.record = null;
      this.dialogDelete = false;
    },
    async deleteItemConfirm() {
      try {
        this.loading = true;
        await ApiService.delete(`items/product_media/${this.record.id}`);
        this.loadData();
        this.loading = false;
        this.closeDelete();

        alert("Product deleted successfully");
        this.loadData();
      } catch (error) {
        console.log(error);
      }
    },
  },
  async mounted() {},
};
</script>
<style lang="css">
@import "~quill/dist/quill.core.css";
@import "~quill/dist/quill.snow.css";
@import "~quill/dist/quill.bubble.css";
.image-area {
  position: relative;
  width: 50%;
  background: #333;
}
.image-area img {
  max-width: 100%;
  height: auto;
}
.remove-image {
  display: none;
  position: absolute;
  top: -10px;
  right: -10px;
  border-radius: 10em;
  padding: 2px 6px 3px;
  text-decoration: none;
  font: 700 21px/20px sans-serif;
  background: #555;
  border: 3px solid #fff;
  color: #fff;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.5), inset 0 2px 4px rgba(0, 0, 0, 0.3);
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
  -webkit-transition: background 0.5s;
  transition: background 0.5s;
}
.remove-image:hover {
  background: #e54e4e;
  padding: 3px 7px 5px;
  top: -11px;
  right: -11px;
}
.remove-image:active {
  background: #e54e4e;
  top: -10px;
  right: -11px;
}
</style>
