<template>
  <v-form ref="form" v-model="valid" style="padding:20px">
    <v-row>
      <!-- user info -->
      <v-col cols="12" md="6">
        <v-text-field
          v-model="productForm.english_title"
          :counter="10"
          label="English Title"
          outlined
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          dir="rtl"
          v-model="productForm.arabic_title"
          :counter="10"
          label="Arabic Title"
          outlined
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <v-textarea
          v-model="productForm.english_description"
          label="English Description"
          outlined
        ></v-textarea>
      </v-col>
      <v-col cols="12" md="6">
        <v-textarea
          dir="rtl"
          v-model="productForm.arabic_description"
          label="Arabic Description"
          outlined
        ></v-textarea>
      </v-col>
      <v-col cols="12" md="6">
        <v-autocomplete
          v-model="productForm.type"
          :items="types"
          dense
          chips
          small-chips
          label="Product Type"
          item-text="title"
          item-value="value"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" md="6">
        <v-autocomplete
          v-model="productForm.status"
          :disabled="userId != 37 && productForm.status == 'rejected'"
          :items="status"
          dense
          chips
          small-chips
          label="Product Status"
          item-text="title"
          item-value="value"
        ></v-autocomplete>
      </v-col>
      <!-- <v-col cols="12" md="4">
        <v-text-field
          v-model="productForm.sequence"
          label="sequence"
        ></v-text-field>
      </v-col> -->
      <v-col cols="12" md="6">
        <v-autocomplete
          v-model="productForm.genre"
          :items="genres"
          dense
          chips
          small-chips
          label="Product Genre"
          item-text="title"
          item-value="value"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" md="6" v-if="productForm.genre == 'B'">
        <v-autocomplete
          v-model="productForm.book_type_id"
          :items="book_type_id"
          dense
          chips
          small-chips
          label="Book Type"
          item-text="english_title"
          item-value="id"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" md="6" v-if="productForm.genre == 'B'">
        <v-autocomplete
          v-model="author"
          :items="authors"
          dense
          multiple
          chips
          small-chips
          label="Product Author"
          item-text="arabic_title"
          item-value="id"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" md="6" v-if="productForm.genre == 'B'">
        <v-autocomplete
          v-model="translator"
          :items="authors"
          multiple
          dense
          chips
          small-chips
          label="Product Translator"
          item-text="arabic_title"
          item-value="id"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" md="6" v-if="productForm.genre == 'B'">
        <v-autocomplete
          v-model="productForm.years"
          :items="years"
          dense
          chips
          small-chips
          label="Years"
          item-text="name"
          item-value="id"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" md="6" v-if="productForm.genre == 'B'">
        <v-text-field
          v-model="productForm.pages"
          :rules="numberRules"
          :counter="10"
          label="Pages"
          outlined
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6" v-if="productForm.genre == 'B'">
        <v-autocomplete
          v-model="productForm.publisher"
          :items="publishers"
          dense
          chips
          small-chips
          label="Product Publisher"
          item-text="arabic_title"
          item-value="id"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" md="6" v-if="productForm.genre == 'I'">
        <v-autocomplete
          v-model="productForm.brand_id"
          :items="brands"
          dense
          chips
          small-chips
          label="Product Brand"
          item-text="english_title"
          item-value="id"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" md="6" v-if="productForm.type == 'S'">
        <v-text-field
          v-model="productForm.actual_price"
          :counter="10"
          label="Actual Price"
          outlined
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6" v-if="productForm.type == 'S'">
        <v-text-field
          v-model="productForm.discounted_price"
          :counter="10"
          label="Discounted Price"
          outlined
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6" v-if="productForm.type == 'S'">
        <v-autocomplete
          v-model="productForm.currency"
          :items="currencies"
          dense
          chips
          small-chips
          label="Product Currency"
          item-text="title"
          item-value="id"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" md="6" v-if="productForm.type == 'S'">
        <v-text-field
          v-model="productForm.quantity"
          :rules="numberRules"
          :counter="10"
          label="Quantity"
          outlined
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6" v-if="productForm.type == 'S'">
        <v-text-field
          v-model="productForm.stock_quantity"
          :counter="10"
          :rules="numberRules"
          label="Stock Quantity"
          outlined
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          v-model="productForm.sku"
          :counter="10"
          label="SKU"
          outlined
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          v-model="productForm.isbn"
          :counter="10"
          label="ISBN"
          outlined
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6" v-if="productForm.genre == 'B'">
        <v-autocomplete
          v-model="productForm.book_language"
          :items="languages"
          dense
          chips
          small-chips
          label="Product Languages"
          item-text="english_title"
          item-value="id"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" md="6" v-if="productForm.type == 'S'">
        <v-text-field
          v-model="productForm.dimensions"
          :counter="10"
          label="Dimensions"
          outlined
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6" v-if="productForm.type == 'S'">
        <v-text-field
          v-model="productForm.shipping_weight"
          :counter="10"
          label="Shipping Weight"
          outlined
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6" v-if="productForm.type == 'S'">
        <v-autocomplete
          v-model="productForm.weight_meg"
          :items="weight_meg"
          dense
          chips
          small-chips
          label="Product Weight Meg"
          item-text="title"
          item-value="value"
        ></v-autocomplete>
      </v-col>

      <v-col cols="12" md="6" v-if="productForm.type == 'S'">
        <v-text-field
          v-model="productForm.height"
          :counter="10"
          label="height"
          outlined
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6" v-if="productForm.type == 'S'">
        <v-autocomplete
          v-model="productForm.height_mes"
          :items="height_mes"
          dense
          chips
          small-chips
          label="Product height Meg"
          item-text="title"
          item-value="value"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" md="6">
        <v-file-input v-model="image" chips label="Select Image"></v-file-input>
      </v-col>

      <v-col cols="12" md="6" v-if="file">
        <div class="image-area" style="margin-bottom: 10px;">
          <img
            :src="file.data.full_url"
            v-if="file"
            width="250px"
            height="250px"
          />
          <a
            class="remove-image"
            @click="removeimg(file.id, 1)"
            href="#"
            style="display: inline;"
            >&#215;</a
          >
        </div>
      </v-col>
      <v-col cols="12" md="12">
        <v-textarea
          v-model="productForm.notes"
          label="Notes"
          outlined
        ></v-textarea>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="10">
        <v-overlay :value="submitted">
          <v-progress-circular
            indeterminate
            size="64"
          ></v-progress-circular> </v-overlay
      ></v-col>
      <v-col cols="12" md="2">
        <v-btn color="success" @click="submit">
          Submit
        </v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import ApiService from "@/services/api.service";

export default {
  props: ["formTitle", "record", "dialog", "userId"],
  data() {
    return {
      snackbar: false,
      valid: false,
      submitted: false,
      loading: false,
      error: "",
      loadingCategories: false,
      authors: [],
      author: [],
      translator: [],
      author_init: [],
      translator_init: [],
      publishers: [],
      languages: [],
      brands: [],
      book_type_id: [],
      currencies: [],
      submitted_succ: false,
      file: null,
      image: null,
      collection: "products",
      types: [
        { title: "Simple", value: "S" },
        { title: "Has Variations", value: "V" },
      ],
      genres: [
        { title: "Book", value: "B" },
        { title: "Item", value: "I" },
      ],
      status: [
        { title: "Published", value: "published" },
        { title: "Draft", value: "draft" },
        { title: "Rejected", value: "rejected" },
      ],
      weight_meg: [
        { title: "kg", value: "kg" },
        // { title: "gm", value: "gm" },
      ],
      height_mes: [
        // { title: "M", value: "M" },
        { title: "CM", value: "CM" },
      ],
      years: [
        { id: 1990, name: "1990" },
        { id: 1999, name: "1999" },
        { id: 2000, name: "2000" },
        { id: 2001, name: "2001" },
        { id: 2002, name: "2002" },
        { id: 2003, name: "2003" },
        { id: 2004, name: "2004" },
        { id: 2005, name: "2005" },
        { id: 2006, name: "2006" },
        { id: 2007, name: "2007" },
        { id: 2008, name: "2008" },
        { id: 2009, name: "2009" },
        { id: 2010, name: "2010" },
        { id: 2011, name: "2011" },
        { id: 2012, name: "2012" },
        { id: 2013, name: "2013" },
        { id: 2014, name: "2014" },
        { id: 2015, name: "2015" },
        { id: 2016, name: "2016" },
        { id: 2017, name: "2017" },
        { id: 2018, name: "2018" },
        { id: 2019, name: "2019" },
        { id: 2020, name: "2020" },
        { id: 2021, name: "2021" },
        { id: 2022, name: "2022" },
        { id: 2023, name: "2023" },
        { id: 2024, name: "2024" },
        { id: 2025, name: "2025" },
      ],
      productForm: {
        id: null,
        type: null,
        genre: null,
        english_title: null,
        arabic_title: null,
        english_description: null,
        arabic_description: null,
        discounted_price: null,
        actual_price: null,
        isbn: null,
        sku: null,
        quantity: null,
        stock_quantity: null,
        image: null,
        dimensions: null,
        height: null,
        shipping_weight: null,
        weight_meg: null,
        height_mes: null,
        book_language: null,
        brand_id: null,
        book_type_id: null,
        publisher: null,
        sequence: 0,
        years: null,
        pages: null,
        currency: null,
        status: "published",
        notes: null,
      },
      textRules: [(v) => !!v || "field is required"],
      numberRules: [
        (v) =>
          Number.isInteger(Number(v)) || "The value must be an integer number",
        // (v) => v > 0 || "The value must be greater than zero",
      ],
      // =================== for updating users ===================
      initialPayload: {},
    };
  },
  methods: {
    async removeimg(id) {
      const { data } = await ApiService.delete(`files/${id}`);
      this.productForm["image"] = null;
      this.submit();
    },

    async submit() {
      try {
        if (this.$refs.form.validate()) {
          let productItem;
          this.submitted = true;
          if (this.image) {
            // send file
            let formData = new FormData();
            formData.append("data", this.image);
            const file = await ApiService.postFile("files", formData, 0, null);
            // send post req
            this.productForm["image"] = file.data.data.id;
          }
          if (this.productForm.id) {
            productItem = await ApiService.update(
              `items/${this.collection}`,
              this.productForm.id,
              this.productForm
            );
          } else {
            productItem = await ApiService.post(
              `items/${this.collection}`,
              this.productForm
            );
            this.productForm.id = productItem.data.data.id;
          }
          await this.remove();
          await this.add(productItem.data.data.id);
        } else {
          this.snackbar = true;
          this.error = "please fill all required fields";
        }
      } catch (error) {
        if (error.message !== "") {
          this.error = error;
        } else {
          this.error = "sorry, something went wrong";
        }
        this.snackbar = true;
        console.log(error);
        this.submitted = false;
      }
    },
    async loadData(item, item1) {
      this.author_init = [];
      this.author = [];
      this.translator_init = [];
      this.translator = [];
      item.forEach((element) => {
        this.author.push(element.author_id.id);
        this.author_init.push(element);
      });
      item1.forEach((element) => {
        console.log(element.author_id.id);
        this.translator.push(element.author_id.id);
        this.translator_init.push(element);
      });
    },
    remove() {
      this.author_init.forEach(async (element) => {
        const result = this.author.filter(
          (author) => author == element.author_id.id
        );
        if (result.length == 0) {
          await ApiService.delete(`items/product_author/${element.id}`);
        }
      });
      this.translator_init.forEach(async (element) => {
        const result = this.translator.filter(
          (translator) => translator == element.author_id.id
        );
        if (result.length == 0) {
          await ApiService.delete(`items/product_translator/${element.id}`);
        }
      });
    },
    add(id) {
      this.author.forEach(async (element) => {
        const result = this.author_init.filter(
          (author) => author.author_id.id == element
        );
        if (result.length == 0) {
          let item = {};
          item["product_id"] = id;
          item["author_id"] = element;
          await ApiService.post(`items/product_author`, item);
        }
      });

      this.translator.forEach(async (element) => {
        const result = this.translator_init.filter(
          (translator) => translator.author_id.id == element
        );
        if (result.length == 0) {
          let item = {};
          item["product_id"] = id;
          item["author_id"] = element;
          await ApiService.post(`items/product_translator`, item);
        }
      });
      this.submitted = false;
      this.submitted_succ = true;
      ///////////
      // this.$emit("formSubmitted", productItem.data.data.id);
      const msg = this.record
        ? "record updated successfully"
        : "record inserted successfully";
      this.$emit("formSubmitted", id, msg);
    },
  },

  async mounted() {
    if (this.record) {
      Object.keys(this.productForm).forEach((element) => {
        if (this.record[element]) {
          if (typeof this.record[element] == "object") {
            this.productForm[element] = this.record[element].id;
          } else {
            this.productForm[element] = this.record[element];
          }
        }
      });
      let item = this.record["authors"];
      let item1 = this.record["translators"];
      this.loadData(item, item1);
    }

    const authors = await ApiService.get(
      "items/author?fields=id,arabic_title&sort=arabic_title"
    );
    this.authors = authors.data.data;

    const publishers = await ApiService.get(
      "items/publisher?fields=id,arabic_title&sort=arabic_title"
    );
    this.publishers = publishers.data.data;

    const brands = await ApiService.get("items/brands?fields=id,english_title");
    this.brands = brands.data.data;

    const book_type_id = await ApiService.get(
      "items/book_type?fields=id,english_title"
    );
    this.book_type_id = book_type_id.data.data;

    const languages = await ApiService.get(
      "items/languages?fields=id,english_title"
    );
    this.languages = languages.data.data;

    const currencies = await ApiService.get("items/currency?fields=id,title");
    this.currencies = currencies.data.data;

    if (this.productForm.image) {
      // await this.getFile(this.formData.image);
      const { data } = await ApiService.getFile(
        "files",
        this.productForm.image
      );
      this.file = data.data;
    }
  },
};
</script>
<style lang="css">
@import "~quill/dist/quill.core.css";
@import "~quill/dist/quill.snow.css";
@import "~quill/dist/quill.bubble.css";
.image-area {
  position: relative;
  width: 50%;
  background: #333;
}
.image-area img {
  max-width: 100%;
  height: auto;
}
.remove-image {
  display: none;
  position: absolute;
  top: -10px;
  right: -10px;
  border-radius: 10em;
  padding: 2px 6px 3px;
  text-decoration: none;
  font: 700 21px/20px sans-serif;
  background: #555;
  border: 3px solid #fff;
  color: #fff;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.5), inset 0 2px 4px rgba(0, 0, 0, 0.3);
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
  -webkit-transition: background 0.5s;
  transition: background 0.5s;
}
.remove-image:hover {
  background: #e54e4e;
  padding: 3px 7px 5px;
  top: -11px;
  right: -11px;
}
.remove-image:active {
  background: #e54e4e;
  top: -10px;
  right: -11px;
}
</style>
